<template>
  <shipblu-prompt
    class="shipment-modal"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="dataModal"
    title=""
    :buttons-hidden="true"
    autocomplete="nofill">
    <img class="w-48 relative title-upfront" src="@/assets/images/pages/titleUpfront.svg">
    <div class="p-5" v-if="taxData">
      <div class="leading-tight col-span-1 mt-2">
        <span class="text-primary text-base font-medium">{{ $t('Tax Profile') }}:</span>
        <span class="text-darkgray text-base"> {{taxData.company && taxData.company.id ? 'Company' : 'Person'}}</span>
      </div>
      <div class="leading-tight col-span-1 mt-2">
        <span class="text-primary text-base font-medium">{{ taxData.company && taxData.company.id ? $t('The Legal Name') : $t('Full Name') }}:</span>
        <span class="text-darkgray text-base"> {{taxData.company ? taxData.company.name : taxData.person.name}}</span>
      </div>
      <div class="leading-tight col-span-1 mt-2">
        <span class="text-primary text-base font-medium">{{ taxData.company && taxData.company.id ? $t('The Tax Registration Number') : $t('National ID') }}:</span>
        <span class="text-darkgray text-base"> {{taxData.company ? taxData.company.tax_registration_number : taxData.person.national_id}}</span>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-8">
        <button @click="upfrontSubcribtion" class="active-btn btn font-semibold">{{ $t('Apply') }}</button>
        <button @click="closeModal()" class="btn disable-btn font-semibold">{{ $t('Back') }}</button>
      </div>
    </div>
  </shipblu-prompt>
</template>
<script>
import { sendRequest } from '../../../http/axios/requestHelper'
import i18nData from '../../../i18n/i18nData'
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'

export default {
  props: ['dataModal', 'taxData'],
  data () {
    return {}
  },
  methods: {
    closeModal () {
      this.$emit('dataModal', false)
      this.$emit('loadTaxProfile')
    },
    upfrontSubcribtion () {
      sendRequest(false, false, this, 'api/v1/merchants/request-upfront/', 'post', {}, true,
        (response) => {
          const merchant = JSON.parse(localStorage.getItem('merchant'))
          merchant.upfront_status = 'requested'
          localStorage.removeItem('merchant')
          localStorage.setItem('merchant', JSON.stringify(merchant))
          this.$vs.notify({
            color:'success',
            title: i18nData[this.$i18n.locale]['Success'],
            text: response.data.message,
            position: 'top-center'
          })
          this.$emit('upfrontRequested', true)
        }
      )
    }
  },
  components: {
    ShipbluPrompt
  },
  created () {
  }
}
</script>
<style>
.title-upfront {
  left: 220px;
  bottom: 20px;
}
</style>