<template>
  <shipblu-card class="p-4">
    <div>
      <div class="items-center mb-2">
        <p class="text-xl font-semibold text-primary">{{$t('Upfront Reports')}}</p>
      </div>
      <shipblu-table
        :sst="true"
        orders
        multiple
        v-model="selected"
        pagination
        :max-items="maximumItems"
        :data="upfrontData"
        :tableLoader="tableLoader"
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse items-center flex-grow justify-between"
        >
        </div>
        <template slot="thead">
          <shipblu-th>{{$t('Pickup Date')}}</shipblu-th>
          <shipblu-th>{{$t('Payout Date')}}</shipblu-th>
          <shipblu-th>{{$t('Payout Method')}}</shipblu-th>
          <shipblu-th>{{$t('Payout Amount')}}</shipblu-th>
          <shipblu-th>{{$t('Status')}}</shipblu-th>
          <shipblu-th>{{$t('Download')}}</shipblu-th>
        </template>
        <template slot-scope="{ data }">
          <shipblu-tr class="inactive-link" :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr].v2 ? data[indextr].v2 : data[indextr])}`">
            <shipblu-td :data="data[indextr].number">
              {{ data[indextr].pickup_date }}
            </shipblu-td>
            <shipblu-td :data="data[indextr].number">
              {{ data[indextr].payout_date }}
            </shipblu-td>
            <shipblu-td :data="data[indextr].payout">
              <template v-if="data[indextr].payout_method">
                <span class="capitalize">Type: {{data[indextr].payout_method.bank ? 'Bank' : 'Wallet'}}</span><br/>
                <span v-if="data[indextr].payout_method.bank">Name: {{data[indextr].payout_method.bank.name}}</span><br/>
                <span>Number: {{data[indextr].payout_method.bank ? data[indextr].payout_method.bank.account_number : data[indextr].payout_method.wallet.number}}</span><br/>
              </template>
            </shipblu-td>
            <shipblu-td :data="data[indextr].net_cod_due">
              {{ Number(data[indextr].payout_amount).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
            </shipblu-td>
            <shipblu-td :data="data[indextr].status" :style="`color: ${getStyle(data[indextr].v2 ? data[indextr].v2 : data[indextr])}`">
              <div class="m-auto flex items-center justify-center font-semibold">
                <div><span class="status-icon mr-1"></span></div>
                <span class="capitalize">{{ $t(getStatusLabel(data[indextr].v2 ? data[indextr].v2 : data[indextr])) }}</span>
              </div>
            </shipblu-td>
            <shipblu-td :data="data[indextr].pdf_link" class="active-link">
              <a @click="downloadUpfront(data[indextr])" target="_blank" class="hover:underline">{{$t('PDF')}}</a> /
              <a @click="downloadExcelUpfront(data[indextr])" target="_blank" class="hover:underline">{{$t('Excel')}}</a>
            </shipblu-td>
          </shipblu-tr>
        </template>
      </shipblu-table>
      <shipblu-pagination :maximumItems="maximumItems" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" @changePageSize="changePageSize" />
    </div>
  </shipblu-card>
</template>
<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import common  from '@/assets/utils/common'
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ShipbluCard from '../../layouts/components/ShipbluCard.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      tabs: ['Needs Review', 'All'],
      tabsDic: {'0': 'Needs Review', '1': 'All'},
      tabsNameDic: {'Needs Review': 0, 'All': 1},
      activeTab: 0,
      upfrontData: [],
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      file_URl: '',
      tableLoader: false,
      latestUpfront: {},
      selected: []
    }
  },
  watch: {
    '$route.query.tab' () {
      this.activeTab = this.tabsNameDic[this.$route.query.tab]
      this.currentPage = 1
      this.loadUpfrontData()
    },
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          tab: this.tabsDic[this.activeTab],
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadUpfrontData()
    },
    'activeTab' () {
      this.upfrontData = []
      this.$router.push({
        query: {
          tab: this.tabsDic[this.activeTab],
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadUpfrontData()
      this.currentPage = 1
    }
  },
  methods: {
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.currentPage = 1
      this.loadUpfrontData()
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(order.status)
    },
    loadUpfrontData () {
      this.tableLoader = true
      const query = `?offset=${this.offset}&limit=${this.maximumItems}`
      sendRequest(true, false, this, `api/v1/upfront/payouts/${query}`, 'get', null, true,
        (response) => {
          if (this.offset === 0) {
            this.latestUpfront = response.data.results.length > 0 ? response.data.results[0] : {}
            this.$emit('latestUpfront', this.latestUpfront)
          }
          this.upfrontData = response.data.results
          this.tableLoader = false
          this.totalRows = response.data.count
        }
      )
    },
    downloadUpfront (data) {
      sendRequest(false, false, this, `api/v1/upfront/payouts/${data.id}/pdf/`, 'get', null, true,
        (response) => {
          this.file_URl = response.data.file_url
          window.open(this.file_URl, '_blank')
        }
      )
    },
    downloadExcelUpfront (data) {
      sendRequest(false, false, this, `api/v1/upfront/payouts/${data.id}/excel/`, 'get', null, true,
        (response) => {
          this.file_URl = response.data.file_url
          window.open(this.file_URl, '_blank')
        }
      )
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluCard,
    ShipbluPagination
  },
  created () {
    this.activeTab = this.$route.query.tab ? this.tabsNameDic[this.$route.query.tab] : '0'
    this.offset = (this.currentPage - 1) * this.maximumItems
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>