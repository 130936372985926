<template>
  <div class="top-up-method-container p-4 flex items-center justify-center">
    <div class="rounded-lg card w-full overflow-y-auto py-6 sm:px-8 px-6 vs-con-loading__container" id="instant-payout-modal">
      <div class="flex items-center justify-between mb-4">
        <p class="font-medium text-black text-2xl">{{$t('Instant Payout')}}</p>
        <p @click="closeModal" class="material-symbols-outlined cursor-pointer text-2xl">close</p>
      </div>
      <div class="gap-4 justify-between sm:flex items-center">
        <div class="w-full" style="max-width: 500px;">
          <div class="flex py-1 text-grey-dark">
            <p class="text-gray">{{$t('Cash Collection Balance')}}</p>
            <div class="flex justify-between w-36 flex-shrink-0 ml-auto">
              <p class="text-gray">{{('EGP')}}</p>
              <p>{{instant.available_cod.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</p>
            </div>
          </div>
          <div class="flex py-1 text-grey-dark">
            <p class="text-gray">{{$t('Services in Progress')}}</p>
            <div class="flex justify-between w-36 flex-shrink-0 ml-auto">
              <p class="text-gray">{{('EGP')}}</p>
              <p>-{{(instant.on_hold_amount + instant.service_fees_due).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</p>
            </div>
          </div>

          <div class="flex py-1 text-grey-dark">
            <p class="text-gray">{{$t('Cash on the way')}}</p>
            <div class="flex justify-between w-36 flex-shrink-0 ml-auto">
              <p class="text-gray">{{('EGP')}}</p>
              <p>-{{(instant.cash_on_the_way).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</p>
            </div>
          </div>

          <div class="flex py-1 text-grey-dark border-dashed gap-x-4 border-b-0 border-r-0 border-l-0 border-grey flex-wrap items-center border">
            <p class="text-gray">{{$t('Available Cash Balance')}}</p>
            <div class="flex justify-between w-36 flex-shrink-0 ml-auto">
              <p class="text-gray">{{('EGP')}}</p>
              <p>{{(Math.max(0, (instant.available_cod - (instant.on_hold_amount + instant.service_fees_due) - instant.cash_on_the_way))).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</p>
            </div>
          </div>
          
          <div class="flex py-1 text-grey-dark">
            <div>
              <p class="text-gray">{{$t('Instant Payout')}}</p>
              <p class="text-gray text-xs">{{$t('80% of Available Cash Balance')}}</p>
            </div>
            <div v-if="!editable" class="flex justify-between w-36 flex-shrink-0 ml-auto">
              <p class="text-gray">{{('EGP')}}</p>
              <div class="leading-tight">
                <p>{{payoutAmount ? payoutAmount.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : (instant.max_payout_amount).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</p>
                <span @click="allowEdit()" class="text-xs underline text-primary cursor-pointer">{{$t('Change')}}</span>
             </div>
            </div>
            <div v-else class="flex w-36 flex-shrink-0 ml-auto">
              <vs-input v-on:input="calcFees()" v-validate="`required|decimal|max_value:${instant.max_payout_amount}`" placeholder="Amount" name="Payout Amount" class="w-full mr-1" v-model="payoutAmount"/>
              <vs-button class="w-3 text-xs" icon-pack="feather" icon="icon-check" color="success" @click="acceptEdit()"></vs-button>
            </div>
          </div>
          <div class="flex justify-end">
            <span class="text-danger text-sm" v-show="errors.has('Payout Amount')">{{ errors.first('Payout Amount') }}</span>
          </div>
          <div class="flex py-1 text-grey-dark gap-x-2">
            <p class="text-gray">{{$t('Instant Payout Fees (1.5%)')}}</p>
            <div class="flex justify-between w-36 flex-shrink-0 ml-auto">
              <p class="text-gray">{{('EGP')}}</p>
              <p>-{{payoutFees > 0 ? payoutFees.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : instant.instant_payout_fees.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</p>
            </div>
          </div>
          <div class="py-1 border-dashed gap-x-4 border-b-0 border-r-0 border-l-0 border-grey font-medium text-xl flex flex-wrap items-center border text-blue-100">
            <p>{{$t('You receive')}}</p>
            <div class="flex justify-between items-center w-36 flex-shrink-0 ml-auto text-lg">
              <p>{{('EGP')}}</p>
              <p>{{payoutAmount ?
                (Number(payoutAmount) - Number(payoutFees)).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) :
                (Number(instant.max_payout_amount) - Number(instant.instant_payout_fees)).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</p>
            </div>
          </div>
        </div>
        <div class="sm:block hidden w-64">
          <img src="@/assets/images/pages/statment-pay.png" class="max-w-full" height="115">
        </div>
      </div>
      <p class="font-medium mt-5">{{$t('Please confirm your request to receive cash for your completed orders today.')}}</p>
      <div class="mt-6 items-center flex justify-end">
        <div @click="confirmPayout" type="filled" class="cursor-pointer  items-center leading-tight gap-1 bg-primary rounded-lg text-white p-3 font-semibold"
        :class="payoutAmount ? (Number(payoutAmount) - Number(payoutFees)) < 0 : (Number(instant.max_payout_amount) - Number(instant.instant_payout_fees)) < 0 ? 'pointer-events-none opacity-50 cursor-not-allowed' : ''">
          <p>{{$t('Confirm')}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import common from '../../../assets/utils/common'
import { sendRequest } from '../../../http/axios/requestHelper'
import i18nData from '../../../i18n/i18nData'
export default {
  props: ['instant'],
  data () {
    return {
      editable: false,
      payoutAmount: '',
      payoutFees: ''
    }
  },
  methods:{
    acceptEdit () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.editable = !this.editable
        }
      })
    },
    calcFees () {
      this.payoutFees = Number(this.payoutAmount) / 100 * 1.5
    },
    allowEdit () {
      this.editable = !this.editable
      this.payoutFees = this.instant.instant_payout_fees
      this.payoutAmount = this.instant.max_payout_amount
    },
    closeModal () {
      this.$emit('instantPayoutModal', false)
    },
    confirmPayout () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading({
            background: this.backgroundLoading,
            color: this.colorLoading,
            container: '#instant-payout-modal',
            scale: 0.45
          })
          let payout = this.payoutAmount ? (Number(this.payoutAmount) - Number(this.payoutFees)) : (Number(this.instant.max_payout_amount) - Number(this.instant.instant_payout_fees))
          payout = payout.toFixed(2)
          sendRequest(true, false, this, 'api/v1/instant/payouts/?dry_run=True', 'post', {payout_amount: Number(payout)}, true,
            (response) => {
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text: response.data.message,
                position: 'top-center'
              })
              this.$router.push({
                name: 'merchant-account-financials',
                query: {
                  tab: 'instant-payout'
                }
              }).catch(() => {})
              this.closeModal()
              this.$vs.loading.close('#instant-payout-modal > .con-vs-loading')
            }, (error) => {
              common.notifyResponseError(this, error)
              this.$vs.loading.close('#instant-payout-modal > .con-vs-loading')
            }
          )
        }
      })
    }
  }
}
</script>