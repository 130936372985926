<template>
  <div>
    <shipblu-card class="mb-4 p-4">
      <div class="flex sm:flex-row flex-col flex-wrap bg-blue-100 border rounded-lg mb-5 p-4 justify-between">
        <div class="sm:py-4">
          <p class="text-white text-2xl font-medium">{{$t('Your Latest Upfront Payout')}}</p>
          <span class="text-white text-lg font-medium">{{latestUpfront ? common.formatDate(latestUpfront.payout_date) : 'N/A'}}</span>
          <p class="font-medium gap-3 flex text-white text-3xl sm:mt-2">
            <span>{{$t('EGP')}}</span>
            <span>{{latestUpfront ? Number(latestUpfront.payout_amount).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0.00'}}</span>
          </p>
        </div>
        <div class="self-end ml-auto">
          <img class="xl:w-52 w-64" src="@/assets/images/pages/statment-pay.png">
        </div>
      </div>
      <div class="px-4">
        <div class="text-darkgray flex sm:flex-row flex-col gap-x-2 justify-between font-medium sm:mt-1 mt-2">
          <p class="font-medium text-xl">{{$t('Cash Collection Balance')}}</p>
          <div class="flex justify-between text-xl w-36 ml-auto">
            <p>{{$t('EGP')}}</p>
            <p>{{latestUpfront ? Number(latestUpfront.total_cod).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0.00'}}</p>
          </div>
        </div>
        <div class="text-darkgray flex sm:flex-row flex-col gap-x-2 justify-between font-medium sm:mt-1 mt-2">
          <p class="font-medium text-xl">{{$t('Service Fees Due')}}</p>
          <div class="flex justify-between text-xl w-36 ml-auto">
            <p>{{$t('EGP')}}</p>
            <p>{{latestUpfront ? Number(latestUpfront.service_fees).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0.00'}}</p>
          </div>
        </div>
        <div class="text-darkgray flex sm:flex-row flex-col gap-x-2 justify-between font-medium sm:mt-1 mt-2">
          <p class="font-medium text-xl">{{$t('Amount On Hold')}}</p>
          <div class="flex justify-between text-xl w-36 ml-auto">
            <p>{{$t('EGP')}}</p>
            <p>{{latestUpfront ? Number(latestUpfront.upfront_fees).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0.00'}}</p>
          </div>
        </div>
      </div>
    </shipblu-card>
    <shipblu-card class="flex items-center justify-between p-4">
      <div class="flex">
        <img class="sm:h-14 h-8" src="@/assets/images/pages/BluScore.svg" >
        <vx-tooltip :text="`${$t('This score represents the average BluScore of your picked orders.')}`">
          <feather-icon class="ml-2 cursor-pointer" icon="HelpCircleIcon" svgClasses="w-4 h-4 text-grey"></feather-icon>
        </vx-tooltip>
      </div>
      <p v-if="bluScoreAvg" class="text-5xl text-success font-semibold">{{bluScoreAvg}}%</p>
      <img v-else class="sm:h-14 h-10" src="@/assets/images/pages/robotBluscore.svg" >
    </shipblu-card>
  </div>
</template>

<script>
import common from '../../../assets/utils/common'
import { sendRequest } from '../../../http/axios/requestHelper'
import ShipbluCard from '../../../layouts/components/ShipbluCard.vue'
export default {
  props: ['latestUpfront', 'merchant'],
  data () {
    return {
      common,
      bluScoreAvg: ''
    }
  },
  methods: {
    bluScore () {
      sendRequest(true, false, this, 'api/v1/analytics/merchant/dashboard/bluscore/', 'get', null, true,
        (response) => {
          this.bluScoreAvg = Number(response.data.average_bluscore) * 100
        }
      )
    }
  },
  components : {
    ShipbluCard
  },
  created () {
    this.bluScore()
  }
}
</script>