<template>
  <shipblu-card class="p-4 h-full">
    <div v-if="merchant.upfront_status === 'subscribed' && latestUpfront.payout_amount" class="relative flex justify-center">
      <img class="w-full max-w-sm" height="350" src="@/assets/images/pages/upfrontSm.svg">
    </div>
    <div v-else class="relative text-center">
      <img class="w-full lg:block hidden" src="@/assets/images/pages/upfront.svg">
      <img class="sm:w-3/4 w-full m-auto lg:hidden block" src="@/assets/images/pages/upfrontSm.svg">
      <vs-button v-if="merchant.upfront_status === 'unsubscribed'" @click="subscribeToUpfront" class="lg:absolute overlay-text px-10 font-semibold sm:text-lg text-sm" :disabled="merchant.is_self_signup && merchant.name === 'Entrepreneur Merchant'">{{$t('Apply for ShipBlu Upfront')}}</vs-button>
      <div v-else class="lg:absolute overlay-text lg:ml-0 ml-4 lg:my-0 my-2">
        <div v-for="(status, index) in statuses" :key="index" :class="statuses.length === ++index ? '' : 'progress-border h-12'" class="block relative">
          <div class="flex">
            <span :class="doneStatus.includes(status.value) || statuses[index - 1].value === currentStatus ? 'current-circle' : 'next-circle'"  
              class="absolute icon-position">
              <i class="material-icons">{{doneStatus.includes(status.value) || statuses[index - 1].value === currentStatus ? 'radio_button_checked' : status.icon}}</i>
            </span>
            <p :class="doneStatus.includes(status.value) || statuses[index - 1].value === currentStatus ? 'text-primary' : 'text-darkgray'" class="self-start ml-5 font-semibold">
              {{$t(status.name)}}
            </p>
          </div>
          <div class="ml-5 text-left" v-if="status.statusDate">
            <p class="text-sm text-lightgray">{{common.gettingDate(status.statusDate)}}</p>
          </div>
        </div>
      </div>
    </div>

    <add-tax-profile :taxModal="taxModal" :typeModal="typeModal" :taxData="taxData" @loadTaxProfile="loadTaxProfile"
      @taxModal="taxModal = $event" @typeModal="typeModal = $event" @taxData="taxData = $event" @upfrontRequested="upfrontRequested = $event"/>
    <up-front-data-confimation :dataModal="dataModal" @dataModal="dataModal = $event" :taxData="taxData" @upfrontRequested="upfrontRequested = $event"/>
  </shipblu-card>
</template>

<script>
import { sendRequest } from '../../../http/axios/requestHelper'
import ShipbluCard from '../../../layouts/components/ShipbluCard.vue'
import AddTaxProfile from '../components/AddTaxProfile.vue'
import UpFrontDataConfimation from './UpFrontDataConfimation.vue'

export default {
  props: ['merchant', 'latestUpfront'],
  data () {
    return {
      taxModal: false,
      typeModal: '',
      taxData: {
        company: {},
        person: {}
      },
      dataModal: false,
      taxes: [],
      statuses: [
        {
          value: 'requested',
          name: 'ShipBlu Upfront Requested',
          icon: 'radio_button_unchecked'
        },
        {
          value: 'subscribed',
          name: 'Agreement Signed',
          icon: 'radio_button_unchecked'
        }
      ],
      doneStatus: [],
      currentStatus: '',
      upfrontRequested: false
    }
  },
  watch: {
    upfrontRequested (val) {
      this.$emit('upfrontRequested', val)
    },
    merchant (val) {
      switch (val.upfront_status) {
      case 'requested':
        this.currentStatus = 'requested'
        this.doneStatus = []
        break
      case 'subscribed':
        this.currentStatus = 'subscribed'
        this.doneStatus = ['requested']
        break
      default:
        break
      }
    }
  },
  methods: {
    subscribeToUpfront () {
      if (this.taxes.length > 0) {
        this.dataModal = true
      } else {
        this.taxModal = true
        this.typeModal = 'Add'
      }
    },
    loadTaxProfile () {
      this.taxData = {
        company: {},
        person: {}
      }
      this.taxProfileLoader = true
      sendRequest(true, false, this, 'api/v1/accounting/tax-profiles/', 'get', null, true, 
        (response) => {
          this.taxes = response.data.results
          this.taxData = response.data.results[0]
          this.taxProfileAdded = response.data.count
          this.$validator.resume()
          this.taxProfileLoader = false
        }
      )
    }
  },
  created () {
    this.loadTaxProfile()
  },
  components: {
    AddTaxProfile,
    UpFrontDataConfimation,
    ShipbluCard
  }
}
</script>

<style lang="scss">
.progress-border {
  border-left: 1px solid #9DADC2;
}
.current-circle {
  left: -12.5px;
  color: #1c5bfe;
}
.next-circle {
  color: rgba(157, 173, 194, 1);
  left: -12px;
}
.icon-position {
  top: -2px;
}
@media (min-width: 992px) {
  .overlay-text {
    bottom: 11%;
  }
  [dir=ltr] .overlay-text {
    left: 11%;
  }
  [dir=rtl] .overlay-text {
    right: 47%;
  }
}
</style>
