<template>
  <div>
    <div class="mb-4 grid gap-4" :class="merchant.upfront_status === 'subscribed' && latestUpfront.payout_amount ? 'xl:grid-cols-2' : ''">
      <greeting-upfront :merchant="merchant" :latestUpfront="latestUpfront" @upfrontRequested="upfrontRequested = $event"></greeting-upfront>
      <update-payout v-if="merchant.upfront_status === 'subscribed' && latestUpfront.payout_amount" :merchant="merchant" :latestUpfront="latestUpfront"></update-payout>
    </div>
    <merchant-upfront v-if="merchant.upfront_status !== 'unsubscribed'" @latestUpfront="latestUpfront = $event"></merchant-upfront>
  </div>
</template>

<script>
import common from '../../assets/utils/common'
import MerchantUpfront from './MerchantUpfront.vue'
import GreetingUpfront from './up-front/GreetingUpfront.vue'
import UpdatePayout from './up-front/UpdatePayout.vue'
export default {
  data () {
    return {
      latestUpfront: {},
      merchant: {},
      upfrontRequested: false
    }
  },
  components: {
    MerchantUpfront,
    GreetingUpfront,
    UpdatePayout
  },
  watch: {
    upfrontRequested () {
      const merchantData = common.checkMerchantInLocalStorage(this)
      merchantData.then(results => {
        this.merchant = results.merchantData
      })  
    }
  },
  created () {
    const merchantData = common.checkMerchantInLocalStorage(this)
    merchantData.then(results => {
      this.merchant = results.merchantData
    })
  }
}
</script>