<template>
  <div id="page-user-view" class="px-4">
    <div class="flex gap-4 items-center justify-between">
      <h2 class="mb-base flex-shrink-0">{{$t('Tax Profile')}}</h2>
      <vs-alert class="mb-base" active="true" color="warning" icon-pack="feather" icon="icon-alert-triangle" v-if="merchantStatus === 'on_hold'">
        {{$t('Your account is')}} <b>{{$t('On hold')}}</b> {{$t('due to inactivity. Please, use the chat icon to talk to our support team and reactivate your account.')}}
      </vs-alert>
      <vs-button v-if="merchantStatus === 'active' && taxProfileAdded === 0" color="primary" class="px-5 py-3 rounded-lg mb-base" @click="newTax('Add')">
        {{$t('Add Tax Profile')}}
      </vs-button>
    </div>
    <shipblu-card :cardLoading="taxProfileLoader" class="p-5" v-if="taxProfileLoader || (!taxProfileLoader && taxProfileAdded > 0)">
      <div class="grid grid-cols-2 gap-4">
        <div class="leading-tight col-span-1">
          <span class="text-darkgray text-base">{{ taxData.company && taxData.company.id ? $t('The Legal Name') : $t('Full Name') }}:</span>
          <span class="text-primary text-base font-medium"> {{taxData.company ? taxData.company.name : taxData.person.name}}</span>
        </div>
        <div class="leading-tight col-span-1">
          <span class="text-darkgray text-base">{{ taxData.company && taxData.company.id ? $t('The Tax Registration Number') : $t('National ID') }}:</span>
          <span class="text-primary text-base font-medium"> {{taxData.company ? taxData.company.tax_registration_number : taxData.person.national_id}}</span>
        </div>
        <div class="leading-tight col-span-1">
          <span class="text-darkgray text-base">{{$t('Governorate')}}:</span>
          <span class="text-primary text-base font-medium"> {{taxData.company ? taxData.company.governorate : taxData.person.governorate}}</span>
        </div>
        <div class="leading-tight col-span-1">
          <span class="text-darkgray text-base">{{$t('City')}}:</span>
          <span class="text-primary text-base font-medium"> {{taxData.company ? taxData.company.city : taxData.person.city}}</span>
        </div>
        <div class="leading-tight col-span-1">
          <span class="text-darkgray text-base">{{$t('Street')}}:</span>
          <span class="text-primary text-base font-medium"> {{taxData.company ? taxData.company.street : taxData.person.street}}</span>
        </div>
        <div class="leading-tight col-span-1">
          <span class="text-darkgray text-base">{{$t('Building Number')}}:</span>
          <span class="text-primary text-base font-medium"> {{taxData.company ? taxData.company.building_number : taxData.person.building_number}}</span>
        </div>
      </div>
      <div class="flex flex-wrap items-center justify-end mt-5" v-if="merchantStatus === 'active'">
        <vs-button class="ml-auto" @click="newTax('Edit')">{{$t('Edit')}}</vs-button>
      </div>
    </shipblu-card>
    <add-tax-profile :taxModal="taxModal" :typeModal="typeModal" :taxData="taxData" @loadTaxProfile="loadTaxProfile"
      @taxModal="taxModal = $event" @typeModal="typeModal = $event" @taxData="taxData = $event"/>
  </div>
</template>

<script>
import ShipbluPrompt from '../../layouts/components/ShipBluPrompt.vue'
import {sendRequest} from '../../http/axios/requestHelper.js'
import vSelect from 'vue-select'
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'
import AddTaxProfile from './components/AddTaxProfile.vue'

export default {
  props: ['merchantStatus'],
  data () {
    return {
      taxModal: false,
      typeModal: '',
      taxProfileAdded: 0,
      taxProfileLoader: false,
      taxData: {
        company: {},
        person: {}
      }
    }
  },
  methods: {
    newTax (typeModal) {
      this.typeModal = typeModal
      this.taxModal = true
    },
    loadTaxProfile () {
      if (this.typeModal !== 'Edit') {
        this.taxData = {
          company: {},
          person: {}
        }
      }
      this.taxProfileLoader = true
      sendRequest(true, false, this, 'api/v1/accounting/tax-profiles/', 'get', null, true, 
        (response) => {
          this.taxData = response.data.results[0]
          this.taxProfileAdded = response.data.count
          this.$validator.resume()
          this.taxProfileLoader = false
        }
      )
    }
  },
  components: {
    ShipbluPrompt,
    vSelect,
    ShipbluCard,
    AddTaxProfile
  },
  created () {
    this.loadTaxProfile()
  }
}
</script>

<style scoped>
.text-darkgray {
  color: #475C6F;
}
</style>